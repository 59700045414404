import "@hotwired/turbo-rails";
import "jquery";
import "./controllers";
import * as ActiveStorage from "@rails/activestorage";
import Rails from "@rails/ujs";
import "chartkick";
import "chartkick/chart.js";

Rails.start();
ActiveStorage.start();
