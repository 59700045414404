import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="offices"
export default class extends Controller {
	static targets = ["office"];

	connect() {
		const selectedOfficeId = localStorage.getItem("selectedOfficeId");
		let officeElement;

		if (selectedOfficeId) {
			officeElement = this.element.querySelector(
				`[data-office-id="${selectedOfficeId}"]`
			);
		} else {
			officeElement = this.element.querySelector(`[data-office-id="default"]`);
		}

		if (officeElement) {
			officeElement.firstElementChild.classList.add("office-card-highlight");
		}
	}

	updateUrl(event) {
		const url = event.currentTarget.getAttribute("href");
		window.history.pushState({}, "", url);
	}

	highlightOffice(event) {
		const officeId = event.currentTarget.getAttribute("data-office-id");

		const childrens = this.officeTarget.parentElement.children;
		const arrayChild = Array.from(childrens);

		arrayChild.forEach((child) => {
			if (child != event.currentTarget) {
				child.firstElementChild.classList.remove("office-card-highlight");
			}
		});
		event.currentTarget.firstElementChild.classList.add(
			"office-card-highlight"
		);

		localStorage.setItem("selectedOfficeId", officeId);
	}
}
