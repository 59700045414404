import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";

export default class extends Controller {
	static targets = ["canvas"];

	connect() {
		const canvas = this.canvasTarget;
		const ctx = canvas.getContext("2d");

		const data = JSON.parse(this.canvasTarget.dataset.chartData);
    const url = this.outlets.controllerElement.baseURI;

		const tick_map = { 1: "Loop", 2: "Good", 3: "Could be better", 4: "Bad" };
		const color_map = {
      1: "#0050ff",
      2: "#49c03f",
      3: "#dda922",
			4: "#f70b08",
		}

    const formatDateTime = (dateTime) => {
      return new Date(dateTime).toLocaleString();
    };

		const chartData = {
			datasets: [
				{
					label: "Feedback",
					data: data.map((value) => ({
						x: formatDateTime(value[0]),
						y: value[1],
						decimals: 0,
					})),
					backgroundColor: "rgba(0, 0, 0, 1)",
					borderColor: "rgba(0,0,0,0)",
					pointRadius: 2,
					pointBackgroundColor: data.map((value) => color_map[value[1]]),
				},
			],
		};

		const chart = new Chart(ctx, {
			type: "line",
			data: chartData,
			options: {
				responsive: true,
				animation: false,
				scales: {
					y: {
            reverse: true,
						grid: {
							display: false,
						},
						ticks: {
							stepSize: 1,
							callback: function (value, index, ticks) {
								return tick_map[value];
							},
							color: function (context) {
								const value = context.tick.value;
								return color_map[value];
							},
						},
					},
					x: {
						grid: {
							display: false,
						},
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						callbacks: {
							labelColor: function (context) {
								const value = data[context.dataIndex][1];
								const color = color_map[value];
								return {
									borderColor: color,
									backgroundColor: color,
								};
							},
						},
					},
				},
			},
		});

		setInterval(() => {
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          let data_entries = data.map((log) => [log.created_at, log.log["id"]]);
          data_entries = data_entries.sort((a, b) => a[0].localeCompare(b[0]));

          const chartData = {
            datasets: [
              {
                label: "Feedback",
                data: data_entries.map((value) => ({
                  x: formatDateTime(value[0]),
                  y: value[1],
                  decimals: 0,
                })),
                backgroundColor: "rgba(0, 0, 0, 1)",
                borderColor: "rgba(0,0,0,0)",
                pointRadius: 2,
                pointBackgroundColor: data_entries.map((value) => color_map[value[1]]),
              },
            ],
          };

          chart.data = chartData;
          chart.update();
        });
		}, 5000);
	}
}
